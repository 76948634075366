<template>
  <b-row
      class="mt-2"
      no-gutters
  >
    <b-col cols="12">
      <div
          class="row comming-soon-box"
      >
        <div class="top-margin-col mr-1">
          <i class="calendar-top d-bg-light-blue icon-calendar"></i>
        </div>
        <div>
          <div class="block-big-month">
            <span> {{ day }} </span>
          </div>
        </div>
        <div class="top-margin-col ml-1">
          <div class="month-range-comming-soon">{{ shortMonth }}</div>
          <div class="hour-range-comming-soon"> {{ time }} </div>
        </div>
        <div class="ml-3">
          <b-row no-gutters>
            <b-col>
              {{ $t('views.client.details.body.right.recent-bookings.booking-label') }} - {{ booking.activity.name }}
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="comming-soon-small-text d-bg-gray">
              {{ playgrounds }}
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="comming-soon-small-text d-bg-gray">
              {{ duration }}
            </b-col>
          </b-row>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {getDuration, MINUTES} from "@/utils/date";

  export default {
    props: {
      booking: {
        type: Object,
        default: () => {},
      }
    },
    computed: {
      day () {
        return this.$moment.utc(this.booking.startAt).format('DD');
      },
      shortMonth () {
        const month = new Date(this.booking.startAt).toLocaleString('default', { month: 'short' });

        return  month.toUpperCase();
      },
      time () {
        return this.$toTimezone(this.booking.startAt).format('HH:mm');
      },
      playgrounds () {
        let string = '';

        for (const i in this.booking.playgrounds) {
          if (parseInt(i) === 0 || parseInt(i) === this.booking.playgrounds.length - 1) {
            string += this.booking.playgrounds[i].name;
          } else {
            string += this.booking.playgrounds[i].name + ', ';
          }
        }
        return string;
      },
      duration () {
        return getDuration(this.$diffUtc({endingDate: this.booking.endAt, startingDate: this.booking.startAt, format: MINUTES}));
      }
    }
  }
</script>
<style lang="scss">
.block-big-month {
  font-size: 40px;
  margin-top: 0px;
  font-weight: 300;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  color: #4D4F5C;
}
.month-range-comming-soon {
  text-align: left;
  margin-top: 0;
  margin-left: 0;
  font: normal normal 300 18px/10px Avenir;
  letter-spacing: 0px;
  color: #4D4F5C;
  opacity: 1;
}

.hour-range-comming-soon {
  text-align: left;
  font: normal normal 17px/10px Avenir;
  margin-top: 8px;
  margin-left: 0;
  letter-spacing: -0.99px;
  color: #0B2772;
  opacity: 1;
}

.comingsoon-title {
  text-align: left;
  font: normal normal 900 18px Avenir;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.calendar-top {
  font-size: 17px;
}

.top-margin-col{
  margin-top: 14px;
}

.comming-soon-small-text {
  text-align: left;
  font: normal normal 14px/20px Avenir;
  letter-spacing: 0px;
}
.comming-soon-box {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 6px;
  border: 2px solid #EBF2F7;border-radius: 8px
}
</style>
